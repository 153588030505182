.photo {
  position: relative;
}

.photo-hero {
  position: relative;
  background-image: url("../../../assets/images/IN1A2953-luke-charles-photographer-frontend-developer.jpg");
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    position: relative;
    height: 100%;
  }

  &__content {
    position: absolute;
    z-index: 1;
    margin-top: rem(100px);
    color: $white;
    max-width: 90%;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 30%);
    z-index: 0;
  }
}

@include media-breakpoint-up(md) {
  .photo-hero {
    &__content {
      margin-top: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 40%;
    }
  }
}

@include media-breakpoint-up(xxlg) {
  .photo-hero {
    &__content {
      max-width: 30%;
    }
  }
}
