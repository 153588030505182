$b-height: 1.15em;
$el-size: 3vh;
$time: 2.1s;
$timedelay: -0.89;

$c_a: rgb(208, 135, 191) rgb(249, 157, 28) rgb(255, 255, 255); // top / bottom / BG
$c2_a: rgb(112, 0, 127) rgb(255, 218, 178);

$ease-circ: cubic-bezier(0.42, 0, 0.58, 1);

.page-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 99999;
  background: $color-primary-1;
  padding-top: 150px;

  &__wrapper {
    width: 100vw;
    text-align: center;
    opacity: 1;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    position: relative;

    > div {
      width: 15px;
      height: 15px;
      background: $color-primary-2;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: pulsedelay 1.4s infinite ease-in-out both;
      animation: pulsedelay 1.4s infinite ease-in-out both;
      transform-style: preserve-3d;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: $el-size;
        height: $el-size;
        border-radius: 50%;
        position: absolute;
      }

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          &:before {
            animation: animBefore $time $ease-circ infinite;
            animation-delay: $i * ($timedelay * $time);
            background-color: nth($c_a, 1);
          }

          &:after {
            animation: animAfter $time $ease-circ infinite;
            animation-delay: $i * ($timedelay * $time);
            background-color: nth($c_a, 2);
          }
        }
      }

      &.circ2 {
        animation-delay: -1.1s;
      }

      &.circ3 {
        animation-delay: -1s;
      }

      &.circ4 {
        animation-delay: -0.9s;
      }

      &.circ5 {
        animation-delay: -0.8s;
      }

      &.circ6 {
        animation-delay: -0.7s;
      }

      &.circ7 {
        animation-delay: -0.6s;
      }

      &.circ8 {
        animation-delay: -0.5s;
      }

      &.circ9 {
        animation-delay: -0.4s;
      }

      &.circ10 {
        animation-delay: -0.3s;
      }
    }
  }
}

@-webkit-keyframes pulsedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.6);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulsedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes animBefore {
  0% {
    top: $el-size * -2;
    z-index: 1;
  }

  25% {
    transform: scale(1.2);
    z-index: 1;
  }

  50% {
    top: $el-size * 2;
    z-index: -1;
  }

  75% {
    background-color: nth($c2_a, 1);
    transform: scale(0.8);
    z-index: -1;
  }

  100% {
    top: $el-size * -2;
    z-index: -1;
  }
}

@keyframes animAfter {
  0% {
    top: $el-size * 2;
    z-index: -1;
  }

  25% {
    background-color: nth($c2_a, 2);
    transform: scale(0.8);
    z-index: -1;
  }

  50% {
    top: $el-size * -2;
    z-index: 1;
  }

  75% {
    transform: scale(1.2);
    z-index: 1;
  }

  100% {
    top: $el-size * 2;
    z-index: 1;
  }
}
