.portfolio {
  margin-bottom: 170px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &__content {
    margin-top: 40px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__column {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
    }
  }
}
