.contact {
  background-color: $white;
  padding: 32px 20px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 43px 73px;
  }

  &__content {
    @include gradient(168.46deg, $bodyGrad);
    padding: 40px 20px;
    border-radius: 16px;

    h2,
    p {
      .project-details__content & {
        color: $white;
      }
    }

    h2 {
      font-weight: 700;
      font-size: rem(30px);
    }

    p {
      max-width: 470px;
      margin: 16px auto 24px;
      font-weight: 500;
    }

    .btn {
      color: $btn-link;
      border: none;

      svg {
        margin-right: 8px;
        vertical-align: baseline;

        path {
          transition: fill 0.3s ease-in-out;
        }
      }

      &:hover,
      &:active {
        color: $white;
        background-color: $brand-dark-purple;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  &--photo-page {
    padding: 0;

    .contact {
      &__content {
        @include gradient(0deg, $photoContact);
        color: $white;
        border-radius: 0;

        .btn {
          background-color: $white;
          border-color: $white;
          color: $grey-900;

          svg {
            path {
              fill: $grey-900;
            }
          }

          &:hover {
            background-color: $grey-700;
            color: $white;

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }

      &__socials {
        margin-top: rem(24px);

        a {
          &:hover,
          &:active {
            color: $grey-700;
          }
        }
      }
    }
  }
}
