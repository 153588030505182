.photo-gallery {
  margin: rem(48px) 0;

  &.dark {
    background-color: $grey-800;
    color: $white;
    margin: 0;
    padding: rem(48px) 0;
  }

  &__grid {
    column-count: 2;
    column-gap: rem(8px);
  }

  &__grid-image {
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: rem(8px);
    break-inside: avoid;

    img {
      max-width: 100%;
      display: block;
      border-radius: rem(15px);
    }

    > img {
      grid-row: 1 / -1;
      grid-column: 1;
    }

    .btn {
      border: none;
      padding: 0;
      border-color: transparent;
      background-color: transparent;
    }
  }
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  border-bottom: 0;
  padding: rem(16px) rem(16px) 0;
}

.modal-content {
  background-color: $grey-800;
  border-radius: rem(15px);
}

.modal-body {
  padding: rem(16px) rem(16px) rem(48px);
}

.photo-gallery__bg-image {
  width: 100%;
  height: 600px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.carousel {
  position: initial;
}

.carousel-item {
  img {
    border-radius: rem(15px);
  }
}

.carousel-inner {
  max-width: 800px;
  margin: 0 auto;
}

.carousel-control-prev {
  left: 36%;
}

.carousel-control-next {
  right: 36%;
}

.carousel-control-prev,
.carousel-control-next {
  width: 12%;
  top: auto;
  bottom: 45px;

  span {
    border: 2px solid rgba($white, 0.5);
    border-radius: rem(4px);
    background-size: 60%;
    width: rem(40px);
    height: rem(40px);
  }

  &:hover {
    span {
      border-color: $white;
    }
  }
}

@include media-breakpoint-up(sm) {
  .photo-gallery {
    &__grid {
      column-count: 2;
    }
  }
}

@include media-breakpoint-up(lg) {
  .photo-gallery {
    &__grid {
      column-count: 3;
    }
  }

  .photo-gallery__bg-image {
    height: 750px;
  }
}

@include media-breakpoint-up(xl) {
  .photo-gallery {
    &__grid {
      column-count: 4;
    }
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-next {
    right: 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 7%;
    top: 0;
    bottom: 0;
  }

  .modal-lg {
    max-width: 1100px;
  }
}
