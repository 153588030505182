.cookie-consent {
  &__buttons {
    .btn-success {
      &:active,
      &:hover {
        background-color: transparent;
      }
    }
  }
}
