.menu {
  position: relative;

  .app-folder__background {
    cursor: pointer;
  }
}

.project-menu {
  position: absolute;
  bottom: 80px;
  left: -16px;
  border-radius: 6px;
  background-color: rgba($brand-dark-purple, 0.95);
  box-shadow: 0 4px 4px 0 rgba($black, 0.25);
  min-width: 224px;

  .light & {
    background-color: rgba($white, 0.97);
    color: $grey-700;
  }

  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 20px;
    height: 14px;
    width: 20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 14px solid rgba($brand-dark-purple, 0.95);

    .light & {
      border-top: 14px solid rgba($white, 0.97);
    }

    @include media-breakpoint-up(sm) {
      transform: translateX(-50%);
      left: 50%;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
  }

  p {
    padding: 12px 16px;
    margin: 0;
    font-size: rem(16px);
    font-weight: 700;
  }

  a {
    padding: 12px 16px;
    display: block;
    font-size: rem(14px);
    border-radius: 4px;
    color: $off-white;
    transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s;

    .light & {
      color: $grey-700;
    }

    &:active,
    &:hover {
      background-color: $brand-light-purple;
      color: $white;
    }
  }
}
