body {
  @include gradient(168.46deg, $bodyGrad);
  font-size: 16px;
  font-weight: normal;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: $body-font;
  animation-duration: 0.75s;
  animation-delay: 0.4s;
  color: $white;
  -webkit-font-smoothing: antialiased;

  &.photo-app {
    color: black;
    @include gradient(0deg, $photo);

    header {
      position: absolute;
      z-index: 1;
      width: 100%;
      padding: rem(16px) 0;
    }
  }

  &.light {
    @include gradient(168.46deg, $bodyGradLight);
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: $white;
  transition: color 400ms;

  &:hover,
  &:active {
    color: $brand-mid-purple;
  }
}

.photo {
  .btn {
    &.dark {
      background-color: $grey-900;
      border-color: $grey-900;

      &:hover {
        background-color: $white;
        color: $grey-900;
      }
    }
  }
}
