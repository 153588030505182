.taskbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px;
  position: fixed;
  width: 95%;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 24px;
  z-index: 20;
  background-color: rgba($brand-darker-purple, 0.50);

  .light & {
    background-color: rgba($white, 0.20);
  }

  // Fix small issue with 'width: fit-content;' on safari
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      width: auto;
    }
  }

  .taskbar__overlay {
    position: fixed;
    top: calc(-100vh + 110px);
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
  }

  @include media-breakpoint-up(md) {
    padding: 12px 16px;
    width: fit-content;
  }

  .app-folder__link {
    margin: 0 4px;

    @include media-breakpoint-up(md) {
      margin: 0 16px;
    }
  }
}
