.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;

  &__content {
    border-radius: 16px;
    max-width: 512px;
    margin: 100px auto;
    background-color: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: $brand-blue;

      &:active,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__message {
    padding: 32px 32px 0;

    p {
      margin: 32px 0;
    }
  }

  &__button {
    padding: 10px 32px 10px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    a {
      background-color: $brand-dark-purple;
      color: $white;
      border: 1px solid transparent;

      &:active,
      &:hover {
        background-color: $white;
        color: $brand-dark-purple;
        border-color: $brand-dark-purple;
        text-decoration: none;
      }
    }
  }
}
