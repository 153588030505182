.two-col,
.two-col-media {
  margin: rem(48px) 0;
}

.two-col-media {
  &.dark {
    background-color: $grey-800;
    color: $white;
  }

  &__bg-image {
    position: relative;
    width: 100%;
    height: 480px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: rem(15px);
    margin-block: rem(16px);
  }

  &__image {
    margin-block: rem(16px);
  }

  img {
    border-radius: rem(15px);
  }
}

@include media-breakpoint-up(md) {
  .two-col-media {
    &__image {
      margin-block: 0;
    }

    &__bg-image {
      margin-block: 0;
    }
  }
}
